(function($){
	$(function(){
		init_func(init_navbar);
		init_func(init_lightbox);
		init_func(init_article);
		init_func(init_toggle);
		init_func(init_search_form);
		init_func(init_event);
	});

	/**
	 * ナビゲーションバー
	 */
	function init_navbar() {
		var $search_btn = $('.navbar-toggle.search');
		$search_btn.off('click').on('click', function(event, arg){
			if ($(this).hasClass('collapsed')) {
				setTimeout(function(){
					// キーワード検索ボタンクリックでフォーカスを当てる。
					$('#keyword_nav').get(0).focus();
					if (arg && arg == 'load') {
						// 初期ロード時には選択なし
					} else {
						// クリックで開いたときはテキスト選択
						$('#keyword_nav').get(0).select();
					}
				}, 300);
			}
		});

		// キーワード検索時、ヘッダーのナビゲーションバーを開く
		if ($search_btn.is(':visible') && $('#keyword_nav').is(':visible')) {
			$search_btn.trigger('click', ['load']);
		}
	}

	/**
	 * Lightbox
	 */
	function init_lightbox() {
		$('.article-image a').colorbox({
			rel: 'article-images'
			, maxWidth: "90%"
			, maxHeight: "90%"
		});
	}

	/**
	 * 記事用のエフェクト
	 */
	function init_article() {
		$('.article-head .slider,.article-image').addClass('onload');
		setTimeout(function(){
			$('.article-head .gear1').removeClass('rotate').addClass('rotate_stop');
			$('.article-head .gear2').removeClass('rotate_r').addClass('rotate_r_stop');
		}, 1000);
	}

	/**
	 * トグル制御
	 */
	function init_toggle() {
		$('.toggle-wrapper').each(function(){
			var $wrapper = $(this);
			var $trigger = $('.toggle-trigger', $wrapper);
			var $target = $('.toggle-target', $wrapper);
			var $icon = $('.glyphicon', $trigger);

			$trigger.off('click').on('click', function(){
				if ($target.is(':visible')) {
					$icon.removeClass('glyphicon-triangle-top').addClass('glyphicon-triangle-bottom');
					$target.slideUp(200);
				} else {
					$icon.removeClass('glyphicon-triangle-bottom').addClass('glyphicon-triangle-top');
					$target.slideDown(200);
				}
			});
		});
	}

	/**
	 * 検索フォーム
	 */
	function init_search_form() {
		// 地域、都道府県のプルダウンはどっちか一つだけを優先
		$('select.change-submit').off('change').on('change', function(){
			$form = $(this).closest('form');
			if ($(this).attr('name') == 'region') {
				$('[name=pref]', $form).val('');
			} else if ($(this).attr('name') == 'pref') {
				$('[name=region]', $form).val('');
			}
			$(this).closest('form').submit();
		});
	}

	/**
	 * イベント一覧、イベントカレンダー
	 */
	function init_event() {
		$('#more-event').on('click', function(){
			var $btn = $(this);
			$btn.prop('disabled', true).text('読み込み中');

			$.ajax({
				url: '/event',
				type: 'get',
				data: {
					more: 1,
					page: $btn.data('page') + 1
				},
				dataType: 'html'
			}).done(function(html){
				$btn.prop('disabled', false).text('もっと見る');

				if (html.trim().length > 0) {
					$btn.data('page', $btn.data('page') + 1);
					$('section#event_list .event_list').append(html);

					// おしまい
					if ($btn.data('page') >= $btn.data('total_page')) {
						$btn.hide();
					}
				} else {
					// もう無い
					$btn.hide();
				}
			});
		});

		if ($('#event_calendar').length == 1) {
			var calendar_date = $('#event_calendar').data('calendar_date');

			$('#event_calendar').fullCalendar({
				header: {
					left: '',
					center: 'title',
					right: ''
				},
				buttonText: {
					prev: '前の月',
					next: '次の月',
					prevYear: '前の年',
					nextYear: '次の年',
					today: '今日',
					month: '月'
				},
				monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
				monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
				dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
				titleFormat: 'Y年M月',
				defaultView: 'month',
				timeFormat: 'hh:mm',
				defaultDate: calendar_date,
				slotEventOverlap: false,
				events: calendar_events,
				eventClick: function(event) {
					if (event && event.article_id) {
						location.href = '/article/view/' + event.article_id;
					}
				},
		        eventRender: function(event, element) {
		            element.attr('title', event.title);
		        },
				dayClick: function(date){
					console.log(date);
				}
			});
		}
	}

})(jQuery);