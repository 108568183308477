(function($){
	var init_funcs = [];

	$(function(){
		init_pjax();
	});

	function init_func(func) {
		init_funcs.push(func);
		func();
	}
	window.init_func = init_func;

	function run_init_funcs($container) {
		$container = $container ? $container : $('body');
    	if (init_funcs.length) {
    		for (var i = 0; i < init_funcs.length; i++) {
    			init_funcs[i]($container);
    		}
    	}
	}
	window.run_init_funcs = run_init_funcs;

	function init_pjax() {
	    if (!$.support.pjax) {
	    	return;
	    }

        $.pjax.defaults.timeout = 1000; // time in milliseconds

        $(document).pjax('a:not(.nopjax)', '#contents .container');
//        $(document).on('submit', 'form', function(event) {
//            $.pjax.submit(event, '#contents .container')
//    	});

        $(document).on('pjax:click', function(event) {
        	if ($(event.target).hasClass('nopjax')) {
        		$.pjax.disable();
        		return false;
        	}
        });

        $(document).on('pjax:start', function() {
        	$('#page-loading').removeClass('hidden');
        });

        $(document).on('pjax:end', function(event, xhr) {
        	// update title
            if (xhr && xhr.getResponseHeader('x-pjax-title')) {
                $('title').text(decodeURIComponent(xhr.getResponseHeader('x-pjax-title')));
            }

            // update menu
        	update_menu(event, xhr);

        	// init funcs
        	run_init_funcs($('#contents .container'));

        	$('#page-loading').addClass('hidden');
        });
	}

	function pjax_reload(url) {
		url = url ? url : location.href;
		$.pjax({url: url, container: '#contents .container'});
	}
	window.pjax_reload = pjax_reload;

	function update_menu(event, xhr) {
		var controller, action, menu_match;

        if (xhr) {
        	controller = xhr.getResponseHeader('x-pjax-controller');
        	action = xhr.getResponseHeader('x-pjax-action');
        	menu_match = xhr.getResponseHeader('x-pjax-menu-match');
        } else {
        	var matches = location.pathname.match(/\/admin(?:\/([^\/]+)\/?([^\/]+)?)?/);
        	if (matches) {
        		controller = matches[1] ? matches[1] : 'index';
        		action = matches[2] ? matches[2] : 'index';
        	}
        }

        if (controller && action) {
            $('#global-menu li').removeClass('active');
            $('#global-menu li.' + controller).addClass('active');

            $('#sub-menu ul').addClass('hidden');
            $('#sub-menu ul.' + controller).removeClass('hidden');

            $('#sub-menu ul.' + controller + ' li').removeClass('active');
            if (menu_match) {
                $('#sub-menu ul.' + controller + ' li.' + controller + '[data-menu_match=' + menu_match + ']').addClass('active');
            } else {
                $('#sub-menu ul.' + controller + ' li.' + controller).addClass('active');
            }
        }
	}
})(jQuery);